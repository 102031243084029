import * as T from './types';

function getCommonsNorthboundAPIs(): T.CommonsNorthboundAPIsType {
  const { v1, v2 } = ((window as any).Shell ||
    {}) as T.CommonsNorthboundAPIsType;

  return { v1, v2 };
}

export default getCommonsNorthboundAPIs;
