import { gql } from '@clientos/graphql-client';

const GET_USER = gql`
  query Query {
    account {
      user {
        fullName
      }
    }
  }
`;

export default GET_USER;
