import { Stack } from '@jarvis/web-stratus-client';

const SERVICES_URLS = {
  HpId: {
    prod: 'https://account.id.hp.com',
    stage: 'https://account.stg.cd.id.hp.com'
  },
  HpSmart: {
    prod: 'https://www.hpsmart.com',
    stage: 'https://consumer.stage.portalshell.int.hp.com'
  },
  QualtricsSurvey: {
    prod: 'https://znaid9q7g0kclquju-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_aid9q7g0kClquJU',
    stage:
      'https://zncuabwr7p241aiia-hpexperience.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_cuaBwr7P241aIia'
  }
};

export const getStack = (): Stack | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (window as any).Shell?.v1.app.getAuthStack();
};

type UrlKey = keyof typeof SERVICES_URLS;

export const getUrl = (key: UrlKey): string => {
  const stack = getStack();
  const devStacks = [Stack.dev, Stack.pie, Stack.stage];
  const env = devStacks.includes(stack) ? 'stage' : 'prod';

  return SERVICES_URLS[key][env];
};

export const getHpIdUrl = (): string => {
  return getUrl('HpId');
};

export const getHpSmartUrl = (): string => {
  return getUrl('HpSmart');
};

export const getQualtricsSurveyUrl = (): string => {
  return getUrl('QualtricsSurvey');
};

export const URLS = {
  Cards: {
    LoginAndSecurity: {
      ChangePassword: `${getHpIdUrl()}/security`,
      UpdatePersonalDetails: `${getHpIdUrl()}/profile`
    },
    YourPurchases: {
      ViewOrderHistory:
        'https://www.hp.com/us-en/shop/MyOrdersView?catalogId=10051&langId=-1&storeId=10151',
      StartReturn:
        'https://www.hp.com/us-en/shop/cv/returnsandexchanges?pStoreID=hpepp'
    },
    InstantInk: {
      UpdatePaymentMethod: `${getHpSmartUrl()}/us/en/ucde/account-details/shipping-billing`,
      ChangePauseOrCancelPlan: `${getHpSmartUrl()}/us/en/ucde/print_plans/my_account`,
      ViewPrintUsage: `${getHpSmartUrl()}/us/en/ucde/print_plans`,
      RequestMoreInk: `https://virtualagent.hpcloud.hp.com/?botClient=HPSmart&lc=en&cc=US&botSubClient=UCDE&psc=true&t=667fd991-f480-483b-ac66-138373c4e5e4&sessionID=6dcce90a02823489d87952dc57b86837`
    },
    HpAllIn: {
      UpdatePaymentMethod: `${getHpSmartUrl()}/us/en/ucde/account-details/shipping-billing`,
      ChangeOrCancelPlan: `${getHpSmartUrl()}/us/en/ucde/hp-all-in-print-plan/update-plan`,
      ViewPrintUsage: `${getHpSmartUrl()}/us/en/ucde/hp-all-in-print-plan`,
      ContactProLiveSupport: `https://support.hp.com/us-en/contact/all-in-plan-serial-number`
    },
    OtherLinks: {
      PrinterShowsAccountIssue: `${getHpSmartUrl()}/ucde/account-details/view-notifications`,
      AddPrinter: `${getHpSmartUrl()}/ucde/my-printers`,
      GetProductSupport: `https://support.hp.com/us-en`,
      RegisterDevice: `https://www.register.hp.com/`
    }
  }
};
