import React, { useEffect, useMemo } from 'react';
import { useQuery } from '@clientos/graphql-client/dist/react';
import {
  useWebcomponent,
  WelcomeNonUsUsers
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import FrameCards from 'src/components/FrameCards';
import GET_USER from 'src/graphql/getUser';
import GET_SUBSCRIPTIONS from 'src/graphql/getSubscriptions';
import getSubscriptions from 'src/utils/getSubscriptions';
import useFeatureFlags, { FeatureFlagsEnum } from 'src/hooks/useFeatureFlags';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import {
  publishEvent,
  screenName,
  screenPath,
  SubscriptionsError,
  UserNameError
} from 'src/utils/analytics';
import { Header } from './styles';

const Home = () => {
  const {
    error: userError,
    loading: userLoading,
    data: userData
  } = useQuery(GET_USER);
  const {
    error: subscriptionsError,
    loading: subscriptionsLoading,
    data: subscriptionsData
  } = useQuery(GET_SUBSCRIPTIONS);
  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);
  const {
    featureFlagValue: welcomeNonUsUsers,
    isLoading: isWelcomeNonUsUsersLoading
  } = useFeatureFlags(FeatureFlagsEnum.welcomeNonUsUsers);
  const { translate, northboundAPIs } = useDependencyManagerContext();

  const { user } = userData?.account || {};
  const { subscriptions } = subscriptionsData?.account || {};
  const language = navigator.language;

  const showQualtricsSurvey = qualtricsSurvey && !isQualtricsSurveyLoading;
  const showWelcomeNonUsUsers =
    welcomeNonUsUsers && !isWelcomeNonUsUsersLoading && language !== 'en-US';

  const welcomeText = () => {
    if (userLoading || userError || !user?.fullName) {
      return <h4>{`${translate('home.welcome', 'Welcome')}`}</h4>;
    }

    return (
      <h4 data-testid="username">{`${translate('home.welcomeUser', {
        defaultValue: 'Welcome, {{user}}',
        replace: { user: user.fullName }
      })}`}</h4>
    );
  };

  const subs = useMemo(() => {
    return getSubscriptions(subscriptions);
  }, [subscriptions]);

  useWebcomponent({
    url: showQualtricsSurvey ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  useEffect(() => {
    if (subscriptionsError) {
      publishEvent(SubscriptionsError(subscriptionsError.message));
    }

    if (userError) {
      publishEvent(UserNameError(userError?.message));
    }
  }, [subscriptionsError, translate, userError]);

  return (
    <>
      {showWelcomeNonUsUsers && (
        <WelcomeNonUsUsers
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          stack={northboundAPIs.v1.app.getAuthStack()}
          translate={translate}
        />
      )}
      <Header data-testid="header">
        <div>
          {welcomeText()}
          <p>
            {`${translate(
              'home.mainTitle',
              'Quick links to your info, subscriptions and purchases with HP'
            )}`}
          </p>
        </div>
      </Header>
      <FrameCards
        subscriptions={subs}
        subscriptionsLoading={subscriptionsLoading}
      />
    </>
  );
};
export default Home;
