import tokens from '@veneer/tokens';
import styled from 'styled-components';

export const xlMin = '1400px';
export const lgMax = '1399px';

export const Frame = styled.div`
  display: grid;
  align-items: stretch;
  align-content: flex-start;
  align-self: stretch;
  justify-content: center;
  gap: ${tokens.layout.size5};
  grid-template-columns: repeat(auto-fill, minmax(366.67px, 1fr));
  width: 100%;
  box-sizing: border-box;
  flex: 1;

  @media (max-width: ${lgMax}) and (min-width: ${tokens.layout.smMin}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${tokens.layout.xsMax}) {
    grid-template-columns: repeat(1, 324px);
  }

  @media (max-width: 402px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
