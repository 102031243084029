import { PublishCdmEventsOptions } from '@jarvis/shell-commons/dist/interface/v1/analytics/types';
import { URLS } from './urls';

const activity = 'UnifiedAcctMgmt-v01';
export const screenPath = '/ReactMyAccountHome/';
export const screenName = 'Home';
const eventDetailVersion = '1.5.0';

export type AnalyticsEventPropTypes = {
  actionAuxParams?: string;
  action: string;
  activity: string;
  screenPath: string;
  screenName: string;
  screenMode?: string;
  controlName?: string;
  controlDetail?: string;
  version: string;
};

export const publishEvent = (
  event: AnalyticsEventPropTypes,
  options?: PublishCdmEventsOptions
): void => {
  const publishCdmEvents = (window as any).Shell?.v1.analytics.publishCdmEvents;

  if (!publishCdmEvents) {
    return;
  }

  const dateTime = new Date().toISOString();
  const cdmEvents = {
    dateTime,
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    eventDetail: event,
    version: '1.5.0'
  };

  publishCdmEvents([cdmEvents], options);
};

const ACTIONS = {
  SCREEN_DISPLAYED: 'ScreenDisplayed',
  CONTROL_HYPERLINK_CLICKED: 'ControlHyperLinkClicked',
  CONTROL_BUTTON_CLICKED: 'ControlButtonClicked',
  MODULE_DISPLAYED: 'ModuleDisplayed'
};

export const HomeScreenDisplayed = {
  action: ACTIONS.SCREEN_DISPLAYED,
  activity,
  screenPath,
  screenName,
  version: eventDetailVersion
};

export const ChangePasswordHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangePassword',
  controlDetail: URLS.Cards.LoginAndSecurity.ChangePassword,
  version: eventDetailVersion
};

export const UpdatePersonalDetailsHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'UpdatePersonalDetails',
  controlDetail: URLS.Cards.LoginAndSecurity.UpdatePersonalDetails,
  version: eventDetailVersion
};

export const ViewOrderHistoryHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ViewOrderHistory',
  controlDetail: URLS.Cards.YourPurchases.ViewOrderHistory,
  version: eventDetailVersion
};

export const StartReturnHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'StartReturn',
  controlDetail: URLS.Cards.YourPurchases.StartReturn,
  version: eventDetailVersion
};

export const PrinterShowsAccountIssueHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'PrinterShowsAccountIssue',
  controlDetail: URLS.Cards.OtherLinks.PrinterShowsAccountIssue,
  version: eventDetailVersion
};

export const AddPrinterHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'AddPrinter',
  controlDetail: URLS.Cards.OtherLinks.AddPrinter,
  version: eventDetailVersion
};

export const GetProductSupportHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'GetProductSupport',
  controlDetail: URLS.Cards.OtherLinks.GetProductSupport,
  version: eventDetailVersion
};

export const RegisterDeviceHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'RegisterDevice',
  controlDetail: URLS.Cards.OtherLinks.RegisterDevice,
  version: eventDetailVersion
};

export const UpdateInstantInkPaymentMethodHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'UpdateInstantInkPaymentMethod',
  controlDetail: URLS.Cards.InstantInk.UpdatePaymentMethod,
  version: eventDetailVersion
};

export const ChangeInstantInkPlanHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangeInstantInkPlan',
  controlDetail: URLS.Cards.InstantInk.ChangePauseOrCancelPlan,
  version: eventDetailVersion
};

export const ViewInstantInkPrintUsageHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ViewInstantInkPrintUsage',
  controlDetail: URLS.Cards.InstantInk.ViewPrintUsage,
  version: eventDetailVersion
};

export const RequestMoreInkHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'RequestMoreInk',
  controlDetail: URLS.Cards.InstantInk.RequestMoreInk,
  version: eventDetailVersion
};

export const UpdateHpAllInPaymentMethodHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'UpdateHpAllInPaymentMethod',
  controlDetail: URLS.Cards.HpAllIn.UpdatePaymentMethod,
  version: eventDetailVersion
};

export const ChangeHpAllInPlanHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ChangeHpAllInPlan',
  controlDetail: URLS.Cards.HpAllIn.ChangeOrCancelPlan,
  version: eventDetailVersion
};

export const ViewHpAllInPrintUsageHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ViewHpAllInPrintUsage',
  controlDetail: URLS.Cards.HpAllIn.ViewPrintUsage,
  version: eventDetailVersion
};

export const ContactHpAllInProLiveSupportHyperlinkClicked = {
  action: ACTIONS.CONTROL_HYPERLINK_CLICKED,
  activity,
  screenPath,
  screenName,
  controlName: 'ContactHpAllInProLiveSupport',
  controlDetail: URLS.Cards.HpAllIn.ContactProLiveSupport,
  version: eventDetailVersion
};

export const ShowInstantInkCardModuleDisplayed = (
  totalSubscriptions: number = 0,
  totalInstantInk: number = 0,
  totalHpAllIn: number = 0
) => {
  return {
    actionAuxParams: `totalSubscriptionsCt=${totalSubscriptions}&totalActiveInstanInkCt=${totalInstantInk}&totalActiveHpAllInCt=${totalHpAllIn}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath,
    screenName,
    controlName: 'ShowInstantInkCard',
    version: eventDetailVersion
  };
};

export const ShowHpAllInCardModuleDisplayed = (
  totalSubscriptions: number = 0,
  totalInstantInk: number = 0,
  totalHpAllIn: number = 0
) => {
  return {
    actionAuxParams: `totalSubscriptionsCt=${totalSubscriptions}&totalActiveInstanInkCt=${totalInstantInk}&totalActiveHpAllInCt=${totalHpAllIn}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath,
    screenName,
    controlName: 'ShowHpAllInCard',
    version: eventDetailVersion
  };
};

export const UserNameError = (errorInfo: string) => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPath,
    screenName: 'UserNameError',
    version: eventDetailVersion
  };
};

export const SubscriptionsError = (errorInfo: string) => {
  return {
    actionAuxParams: `error=${errorInfo?.slice(0, 256)}`,
    action: ACTIONS.MODULE_DISPLAYED,
    activity,
    screenPath: screenPath,
    screenName: 'SubscriptionsError',
    version: eventDetailVersion
  };
};
