import { gql } from '@clientos/graphql-client';

const GET_SUBSCRIPTIONS = gql`
  query Query {
    account {
      subscriptions {
        type
        state
      }
    }
  }
`;

export default GET_SUBSCRIPTIONS;
