import {
  getSubscriptionCommercialState,
  ApiSubscriptionStates,
  CommercialSubscriptionStates,
  SubscriptionType
} from '@hpx-core-experiences/react-my-account-commons/dist/index';

const checkCorrectSubscriptionState = (
  state: ApiSubscriptionStates,
  type: SubscriptionType
) => {
  const translatedSubscriptionState = getSubscriptionCommercialState(
    state,
    type
  );

  if (
    !translatedSubscriptionState ||
    translatedSubscriptionState === CommercialSubscriptionStates.INACTIVE ||
    // The following states are considered as inactive and can happen if there is a problem with the subscription type
    translatedSubscriptionState === ApiSubscriptionStates.OBSOLETE ||
    translatedSubscriptionState === ApiSubscriptionStates.UNSUBSCRIBED ||
    translatedSubscriptionState === ApiSubscriptionStates.INACTIVE
  ) {
    return false;
  }

  return true;
};

/**
 * Checks if user has a subscription for Instant Ink and/or HP All In with state different than Inactive.
 * @param subscriptions - An array of subscription objects containing state and type.
 * @returns An object containing the total number of subscriptions, total number of Instant Ink and HP All In subscriptions.
 */
const getSubscriptions = (
  subscriptions:
    | { state: ApiSubscriptionStates; type: SubscriptionType }[]
    | null
) => {
  if (!subscriptions) {
    return null;
  }

  let hasActiveInstantInk = false;
  let hasActiveHpAllIn = false;
  let totalHpAllIn = 0;
  let totalInstantInk = 0;

  subscriptions.forEach(({ state, type }) => {
    switch (type) {
      case SubscriptionType.HP_ALL_IN:
        totalHpAllIn++;
        if (!hasActiveHpAllIn) {
          hasActiveHpAllIn = checkCorrectSubscriptionState(state, type);
        }
        break;
      case SubscriptionType.INSTANT_INK:
        totalInstantInk++;
        if (!hasActiveInstantInk) {
          hasActiveInstantInk = checkCorrectSubscriptionState(state, type);
        }
        break;
    }
  });

  return {
    hasActiveInstantInk,
    hasActiveHpAllIn,
    total: subscriptions.length || 0,
    totalHpAllIn: totalHpAllIn,
    totalInstantInk: totalInstantInk
  };
};

export default getSubscriptions;
